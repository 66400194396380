
import Vue from "vue";
import DatePicker from "@/components/form-components/DatePicker.vue";
import store from "@/store";
import LiveSearch from "@/components/form-components/LiveSearch.vue";
import API from "@/api/API";
import ProductsPicker from "../../ProductsPicker.vue";
import rules from "@/services/helpers/validation-rules";
import Payments from "@/modules/orders/components/Payments.vue";
import { mask } from "vue-the-mask";
import allowedFields from "../../../config/available-fields.json";
import ClientData from "@/modules/orders/components/ClientData.vue";
import Documents from "@/modules/orders/components/Documents.vue";
import Allocation from "@/modules/orders/components/Allocation.vue";
import OrderServices from "@/modules/orders/components/OrderServices.vue";
import Allocations from "@/modules/orders/mixins/allocations";
import Comments from "@/modules/orders/mixins/comments";
import Services from "@/modules/orders/mixins/services";
import Client from "@/modules/orders/mixins/client";
import CommonActions from "@/modules/orders/mixins/common_actions";

export default Vue.extend({
  name: "AtaEditOrder",

  components: {
    OrderServices,
    Allocation,
    ClientData,
    Payments,
    ProductsPicker,
    LiveSearch,
    DatePicker,
    Documents
  },

  mixins: [Allocations, Comments, Client, Services, CommonActions],

  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({})
    },
    currentStatusAlias: {
      type: String,
      required: true,
      default: ""
    }
  },

  directives: { mask },

  data: () => ({
    rules,
    allowedFields: allowedFields as any,
    selectedDocument: null as null | SelectComponentInterface,
    model: {
      documents: [] as Array<any>,
      goods: [] as Array<any>,
      countries: [] as Array<any>,
      order: {
        source: "operator",
        valid_form: new Date().toISOString().substr(0, 10)
      } as any
    } as any,
    comments: {} as any,
    oldModel: null as any,
    scanned_documents: [] as Array<any>,
    allDelegatesText: {
      ro: "Toate persoanele împuternicite",
      en: "All authorized persons",
      ru: "Все уполномоченные лица"
    } as any,
    errorMessages: {} as { [value: string]: Array<string> },
    selects: {
      cardTypes: [] as Array<any>,
      managers: [] as Array<any>,
      countries: [] as Array<any>,
      purpose: [] as Array<any>,
      packingCategories: [] as Array<any>,
      transports: [] as Array<any>,
      currencies: [] as Array<any>,
      units: [] as Array<any>,
      languages: [] as Array<any>,
      client_types: [] as Array<any>,
      release_types: [] as Array<any>,
      c: [] as Array<any>,
      services: [] as Array<any>
    } as any,
    lang: store.getters["localization/getCurrent"],
    allDelegates: true as boolean,
    client: { type: "juridical" as string } as any,
    currency: { value: 1.0, text: "MDL" } as any,
    selectedTab: 0,
    selectedCurrency: {} as any,
    totalSum: 0 as number,
    isShowCountries: {
      destination: false as boolean,
      transit: false as boolean
    } as any,
    isShowDocuments: true as any,
    isLoading: false as boolean,
    loading: false as boolean,
    emptyDocument: null as any,
    loadingPayments: false as boolean,
    loadingCarnetIssused: false as boolean,
    isShowDocumentsNumber: false as boolean,
    changeStatusDialog: true as boolean,
    extraServices: {} as any,
    extraOutputs: 0 as number,
    additionSumm: 0 as number,
    breadcrumb: [
      {
        text: "orders.title",
        disabled: false,
        href: "/orders"
      },
      {
        text: "orders.form.header.edit",
        disabled: true,
        href: `/orders/edit`
      }
    ],
    showForm: true as boolean,
    orderStatuses: [] as Array<any>,
    statuses: {} as any,
    powerOfAttorney: null as any,
    paymentsTotalPrice: 0 as number,
    dialog: false,
    defaultCountry: [] as Array<number>,
    packingCategory: null as any,
    measureUnit: null as any,
    transportCategory: null as any,
    updatedComponents: [] as Array<any>
  }),

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler() {
        this.model = this.value;

        if (this.model.hasOwnProperty("additions")) {
          if (this.model.additions.hasOwnProperty("notes")) {
            this.selectedTab = 0;
            this.comments = this.parseComments(this.model.additions.notes);
          }
        }

        if (this.model.hasOwnProperty("parent_documents")) {
          const [item] = this.model.parent_documents;
          if (item) {
            this.selectedDocument = { ...item };
          }
        }
      }
    },
    "model.order.language"() {
      if (this.allDelegates) {
        this.model.order.client_delegate = this.allDelegatesText[
          this.model.order.language
        ];
      }
    },
    goodsSum() {
      if (this.goodsSum && this.model.details.guaranty_required) {
        this.model.details.required_guaranty_sum =
          Math.trunc(0.3 * this.goodsSum) || 0;
      }
    }
  },

  computed: {
    isClassic() {
      return this.model.order.power_of_attorney === "classic";
    },
    formattedDate() {
      return this.$moment(this.model.order.document_valid_from, "YYYY-MM-DD")
        .add(1, "years")
        .subtract(1, "days")
        .format("DD.MM.YYYY");
    },
    goodsSum() {
      let sum = 0;
      for (const item of this.model.details.countries) {
        if (item.quantity * item.price) {
          sum += item.quantity * item.price;
        }
      }
      return sum;
    },
    parentLanguage() {
      return this.model.order.language === "ro";
    },
    isDataChanged() {
      if (this.model && this.oldModel) {
        return JSON.stringify(this.model) !== JSON.stringify(this.oldModel);
      }
      return false;
    },
    isServiceChanged() {
      if (this.model && this.oldModel) {
        return (
          JSON.stringify(this.model.details.services) !==
          JSON.stringify(this.oldModel.details.services)
        );
      }
      return false;
    },
    canChangedReleaseMode() {
      return this.oldModel?.order.release_mode === "urgent";
    },
    destinationCountries() {
      return this.model.details.countries.filter(
        (item: any) => item.scope === "destination"
      );
    },
    transitedCountries() {
      return this.model.details.countries.filter(
        (item: any) => item.scope === "transit"
      );
    },
    minVisits() {
      if (this.oldModel && this.oldModel.order) {
        return this.model.details.outputs;
      }
      return 0;
    },
    maxVisits() {
      if (!this.isReadonly("outputs")) {
        return 255;
      }
      return 510;
    },
    isJuridicalPerson() {
      return this.model.order?.client?.type === "juridical";
    },
    isBeneficiaryAndProcessing() {
      return (
        this.model.order.status.id === 1 && this.model.order.source === "client"
      );
    },
    orderFulfilled() {
      return this.model.order.status.id === 4;
    },
    statusChanged() {
      return this.currentStatusAlias !== this.model.order.status.alias;
    },
    currentRole() {
      return this.$store.getters["user/currentRole"];
    },
    isBookkeeper() {
      return (
        this.currentRole.alias === "bookkeeper" ||
        this.currentRole.alias === "bookkeeper_chief"
      );
    },
    isAtaSelected() {
      return this.selects.cardTypes.slice(0, 3);
    },
    correctManagers() {
      return this.selects.managers
        .filter((item: any) => {
          return item.value === 69 || item.value === 112;
        })
        .reverse();
    }
  },

  async mounted() {
    await this.loadData();
  },

  methods: {
    setPaymentsPrice(price: number) {
      this.paymentsTotalPrice = price;
    },
    setReleaseMode(event: any): void {
      this.model.order.release_mode = event;
      this.model.order = JSON.parse(JSON.stringify(this.model.order));
      setTimeout(() => {
        this.$forceUpdate();
      }, 200);
    },
    setDefaultTab() {
      if (
        this.model.order.status &&
        ["processing", "issued", "canceled"].includes(
          this.model.order.status.alias
        )
      ) {
        this.selectedTab = 1;
        return;
      } else {
        this.selectedTab = 2;
      }
    },
    async getOrderStatuses() {
      try {
        this.orderStatuses = await this.$API
          .orders()
          .getOrderStatuses(this.$route.params.id);
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async loadData() {
      this.loading = true;
      try {
        const headers = {
          "x-localization": this.model.order.language
        };

        this.scanned_documents = await API.orders().getScannedDocuments(
          Number(this.$route.params.id)
        );

        const [
          currencies,
          transports,
          managers,
          purpose,
          countries,
          services,
          languages,
          releaseModes,
          clientTypes,
          cardTypes,
          units,
          packingCategories,
          branches
        ] = await Promise.all([
          API.currency().getList(),
          API.transportTypes().getList(headers),
          API.employees().getList(),
          API.purposeOfUse().getList(this.model.order.service_type_id, headers),
          API.countries().getAcceptAta(),
          API.services().getListByServiceType(this.model.order.service_type_id),
          API.orders().getLanguages(),
          API.orders().getOrderReleaseModes(),
          API.clients().getClientTypes(),
          API.orders().getCardTypes(this.model.order.service_type_id),
          API.measureUnits().getList({
            service_type_id: this.model.order.service_type_id
          }),
          API.packingCategories().getList(headers),
          API.branches().getList()
        ]);

        this.client = this.model.order.client;
        this.selects.currencies = currencies.items;
        this.selects.transports = transports.items;
        this.selects.managers = managers.items;
        this.selects.purpose = purpose;
        this.selects.countries = countries.items;
        this.selects.services = services;
        this.selects.languages = languages.items;
        this.selects.release_types = releaseModes.items;
        this.selects.client_types = clientTypes;
        this.selects.cardTypes = cardTypes.items;
        this.selects.units = units;
        this.selects.packingCategories = packingCategories;
        this.selects.branches = branches;
        this.selectedCurrency = this.model.details.currency_id;

        if (countries.default) {
          this.defaultCountry = countries.default;
          if (!this.model.details.countries.length) {
            countries.default.forEach((country_id: number) => {
              this.model.details.countries.push({
                country_id,
                scope: "destination"
              });
            });
          }
        }

        this.currency = this.selects.currencies.find(
          (value: any) => value.value === Number(this.model.details.currency_id)
        );

        if (this.model && this.model.details.services) {
          this.model.details.services?.forEach((item: any) => ({
            ...item,
            _isDeleted: false
          }));
        }

        const statuses = await this.$API.orders().getStatuses();

        for (const key in statuses) {
          this.statuses[key] = statuses[key];
        }
        await this.getOrderStatuses();

        this.getPackingCategory();
        this.getMeasureUnit();
        this.getTransportCategory();

        this.setDefaultTab();
        this.$nextTick(() => {
          this.fullLoaded = true;
        });

        const dataObject = {
          experts: this.model.experts,
          status: this.model.order.status
        };

        this.$emit("getExperts", dataObject);
      } catch (e) {
        await store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    async loadSelects() {
      this.loading = true;
      try {
        const headers = {
          "x-localization": this.model.order.language
        };

        const [
          transports,
          managers,
          purpose,
          packingCategories,
          branches
        ] = await Promise.all([
          API.transportTypes().getList(headers),
          API.employees().getList(
            { filter: { subdivisions: this.model.order.subdivision_id } },
            headers
          ),
          API.purposeOfUse().getList(1, headers),
          API.packingCategories().getList(headers),
          this.$API.branches().getList()
        ]);

        this.selects.transports = transports.items;
        this.selects.managers = managers.items;
        this.selects.purpose = purpose;
        this.selects.packingCategories = packingCategories;
        this.selects.branches = branches;
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    isReadonly(field: string) {
      return this.allowedFields?.[this.model.order.status?.alias]?.includes(
        field
      );
    },
    setServerResponse(response: any) {
      this.model = response;

      this.selectedCurrency = response.details.currency_id;

      this.$nextTick(() => {
        this.oldModel = JSON.parse(JSON.stringify(this.model));
        this.listenChangeFieldEvent();
        this.$forceUpdate();
      });
    },
    listenChangeFieldEvent() {
      const form = this.$refs.form as Vue;

      for (const formElement of form.inputs) {
        let oldValue = formElement.value;

        if (typeof oldValue === "number") {
          oldValue = oldValue.toString();
        }

        formElement.$on("input", (value: any) => {
          const domElement = formElement.$el.querySelector(".v-input__slot");

          if (JSON.stringify(oldValue) != JSON.stringify(value)) {
            domElement.style.background = "rgba(247, 180, 2, 0.2)";
          } else {
            domElement.style.background = "";
          }
        });
      }
    },
    async submit(): Promise<void> {
      try {
        const form = this.$refs.form as Vue;

        if (form.validate()) {
          this.isLoading = true;
          this.setPackingCategoryId();
          this.setMeasureUnitId();
          this.setTransportCategoryId();

          const model = JSON.parse(JSON.stringify(this.model));
          model.documents = this.model.documents;

          if (this.powerOfAttorney?.file) {
            this.model.documents.push(this.powerOfAttorney);
          }

          let parsedModel = this.parseModel(model);

          if (
            this.updatedComponents.length === 1 &&
            this.updatedComponents.includes("allocation")
          ) {
            parsedModel = {
              ...parsedModel,
              updated: "allocation"
            };
          }

          this.model = await this.$API
            .orders()
            .edit(Number(this.$route.params.id), parsedModel);

          this.model.details.services?.forEach((item: any) => ({
            ...item,
            _isDeleted: false
          }));
          this.extraOutputs = 0;
          form.updateGuard();
          this.dialog = !this.statusChanged;

          if (!this.dialog) {
            this.$emit("update");
          }
          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("global_alert.successful_edit")
          );
          this.isLoading = false;
        } else {
          await setTimeout(() => {
            const firstErrorInput = form.inputs.find(
              (item: any) => item.messagesToDisplay.length
            );

            if (firstErrorInput) {
              this.$vuetify.goTo(firstErrorInput.$el, {
                offset: 150
              });
            }
          }, 500);
        }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          if (
            e.errors.hasOwnProperty("order") &&
            e.errors.order.hasOwnProperty("required_guaranty_sum")
          ) {
            this.selectedTab = 1;
          }

          this.errorMessages = e.errors;
          this.selectedTab = 1;
          setTimeout(() => {
            this.errorMessages = {};
          }, 2000);
        }
        await this.$store.dispatch("alert/showError", e.message || e);
        this.isLoading = false;
      }
      this.forceReload();
    },
    addCountry(country: any, scope: string) {
      if (this.isDefaultCountry(country.value)) {
        return;
      }
      if (
        this.model.details.countries.some(
          (item: any) =>
            item.country_id === country.value && item.scope === scope
        )
      ) {
        const countryIndex = this.model.details.countries.findIndex(
          (item: any) =>
            item.country_id === country.value && item.scope === scope
        );
        if (countryIndex !== -1) {
          this.model.details.countries.splice(countryIndex, 1);
        }
      } else {
        this.model.details.countries.push({ country_id: country.value, scope });
      }
    },
    async getExchangeRate(item: any): Promise<void> {
      try {
        this.currency = item;
        this.model.details.currency_id = item.value;

        if (item.text === "MDL") {
          this.model.details.exchange_rate = "1.0000";
          return;
        }

        const response = await this.$API
          .exchangeRates()
          .getExchangeRate(item.value);

        if (response) {
          this.model.details.exchange_rate = response.value;
        }
      } catch (e) {
        this.model.details.exchange_rate = "1.0000";
        await this.$store.dispatch(
          "alert/showError",
          this.$t("orders.form.fields.no_rate")
        );
      }
    },
    changeData(items: any[]) {
      this.model.details.countries = items;
    },
    changeDelegate() {
      if (this.allDelegates) {
        this.model.order.client_delegate = this.allDelegatesText[
          this.model.order.language
        ];
      } else {
        this.model.order.client_delegate = "";
      }
    },
    setParentDocument(card: any) {
      if (card.hasOwnProperty("value")) {
        this.model.parent_documents = [card];
      }
    },
    updateVat(index: number): void {
      this.model.details.services[index].sum_no_vat = Number(
        this.model.details.services[index].sum_no_vat
      );
      this.model.details.services[index].sum_with_vat = this.addVat(
        Number(this.model.details.services[index].sum_no_vat)
      );
      this.model.details.services[index].remained_sum =
        Number(this.model.details.services[index].sum_with_vat) -
        Number(this.model.details.services[index].paid_sum);
      this.getTotalSum();
    },
    canEditField(key: string, field: string): boolean {
      /*if (!this.model.fields_access) {
        return true;
      }

      if (!this.model.fields_access[key]) {
        return false;
      }

      if (this.model.fields_access[key].length) {
        return !this.model.fields_access[key].includes(field);
      }*/
      return true;
    },
    toggleTransitedCountries(): void {
      this.isShowCountries.transit = !this.isShowCountries.transit;
    },
    toggleDestinationCountries(): void {
      this.isShowCountries.destination = !this.isShowCountries.destination;
    },
    setPurposeDescription(ev: any) {
      if (ev?.hasOwnProperty("description")) {
        this.model.details.purpose_description = ev.description;
      }
    },
    onTabChange(tabIndex: number): void {
      this.loadingPayments = tabIndex === 2;
      this.loadingCarnetIssused = tabIndex === 3;
    },
    getExtraVisitsService(service: any) {
      this.extraServices.extraPositions = service;
      delete this.extraServices.dataEdit;
    },
    getDataEditService(service: any) {
      if (!this.extraServices.extraPositions) {
        this.extraServices.dataEdit = service;
      } else {
        delete this.extraServices.dataEdit;
      }
    },
    setAdditionSum(event: number): void {
      this.additionSumm = event;
    },
    async getData(changeTab = false) {
      await this.$emit("update");
      if (changeTab) {
        this.selectedTab = 1;
      }
      this.updatedComponents.push("payments");
      // try {
      //   const response = await API.orders().getForEdit(
      //     Number(this.$route.params.id)
      //   );
      //
      //   this.$forceUpdate();
      //
      //   this.setServerResponse(response);

      // } catch (e) {
      //   await this.$store.dispatch("alert/showError", e.message || e);
      // }
    },
    setAtaExposition() {
      const ataExpositionService = this.model.details.services.find(
        (item: any) => item.alias === "ata_exposition_discount"
      );

      if (ataExpositionService) {
        ataExpositionService._isDeleted = !ataExpositionService._isDeleted;
      }
    },
    isCheckedCountry(country: any, scope: string): boolean {
      return this.model.details.countries?.some(
        (item: any) => item.country_id === country.value && item.scope === scope
      );
    },
    setGuaranteePrice() {
      if (this.model.details.guaranty_required) {
        this.model.details.required_guaranty_sum =
          Math.trunc(0.3 * this.goodsSum) || 0;
      }
    },
    forceReload() {
      this.showForm = false;
      this.$nextTick(() => {
        this.showForm = true;
      });
    },
    parseModel(model: any) {
      model.details.outputs =
        Number(model.details.outputs) + Number(this.extraOutputs);

      if (typeof model.details.purpose_id !== "number") {
        model.details.purpose_id = model.details.purpose_id?.value;
      }

      model.order.document_valid_until = this.$moment(
        this.model.order.document_valid_from,
        "YYYY-MM-DD"
      )
        .add(1, "years")
        .subtract(1, "days")
        .format("YYYY-MM-DD");

      model.details.services = model.details.services.filter(
        (item: any) => !item._isDeleted
      );

      if (Object.keys(this.extraServices).length > 0) {
        for (const key in this.extraServices) {
          if (this.extraServices[key] && this.extraServices[key].service_id) {
            model.details.services.push(this.extraServices[key]);
          }
        }
      }

      if (this.parentLanguage) {
        for (const key in model.details.countries) {
          model.details.countries[key].name_ro =
            model.details.countries[key].name;
        }
      }

      if (!model.details.guaranty_required) {
        model.details.required_guaranty_sum = 0;
      }

      if (
        model.hasOwnProperty("parent_documents") &&
        Array.isArray(model.parent_documents)
      ) {
        model.parent_documents = model.parent_documents.map((item: any) => {
          if (item.hasOwnProperty("value")) {
            return item.value;
          }

          if (item.hasOwnProperty("id")) {
            return item.id;
          }

          return item;
        });
      }
      return model;
    },
    removePackingCategory(): void {
      this.model.details.packing_category_id = null;
    },
    changeOrderStatus() {
      this.$emit("showStatusDialog");
    },
    loadDocuments(val: any) {
      this.model.documents = val;
    },
    loadScannedDocuments(val: any) {
      this.scanned_documents = val;
    },
    async sendScannedDocuments() {
      try {
        await this.$API
          .orders()
          .sendScannedDocuments(
            Number(this.$route.params.id),
            this.scanned_documents
          );
        await this.$store.dispatch(
          "alert/showSuccess",
          this.$t("global_alert.successful_edit")
        );
      } catch (e) {
        if (e.message) {
          await this.$store.dispatch("alert/showError", e.message);
        }
      }
    },
    async cloneOrder(): Promise<void> {
      try {
        await this.$API.orders().cloneOrder(this.model.order.id);
        await this.$store.dispatch(
          "alert/showSuccess",
          this.$t("orders.modals.alert.successful_cloned")
        );
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    canEditForm() {
      return this.model.order.status.alias !== "issued";
    },
    reload(): void {
      this.$emit("update");
    },
    toggleView(): void {
      this.$router.push(`/orders/preview/${this.$route.params.id}`);
    },
    isDefaultCountry(country_id: number) {
      return this.defaultCountry.includes(country_id);
    },
    // closeConfirm() {
    //   this.dialog = false;
    //   this.$emit("update");
    // }
    getPackingCategory(): void {
      const id = this.model.details.packing_category_id;

      if (typeof id === "string") {
        this.packingCategory = id;
      } else {
        for (const item of this.selects.packingCategories) {
          if (item.value === id) {
            this.packingCategory = item;
            break;
          }
        }
      }
    },
    getMeasureUnit(): void {
      const id = this.model.details.measure_unit_id;

      if (typeof id === "string") {
        this.measureUnit = id;
      } else {
        for (const item of this.selects.units) {
          if (item.value === id) {
            this.measureUnit = item;
            break;
          }
        }
      }
    },
    getTransportCategory(): void {
      const id = this.model.details.transport_category_id;

      if (typeof id === "string") {
        this.transportCategory = id;
      } else {
        for (const item of this.selects.transports) {
          if (item.value === id) {
            this.transportCategory = item;
            break;
          }
        }
      }
    },
    setPackingCategoryId(): void {
      if (this.packingCategory) {
        const index = this.selects.packingCategories.indexOf(
          this.packingCategory
        );

        if (index === -1) {
          this.model.details.packing_category_id = this.packingCategory;
        } else {
          this.model.details.packing_category_id = this.selects.packingCategories[
            index
          ].value;
        }
      }
    },
    setMeasureUnitId(): void {
      if (this.measureUnit) {
        const index = this.selects.units.indexOf(this.measureUnit);

        if (index === -1) {
          this.model.details.measure_unit_id = this.measureUnit;
        } else {
          this.model.details.measure_unit_id = this.selects.units[index].value;
        }
      }
    },
    setTransportCategoryId(): void {
      if (this.transportCategory) {
        const index = this.selects.transports.indexOf(this.transportCategory);

        if (index === -1) {
          this.model.details.transport_category_id = this.transportCategory;
        } else {
          this.model.details.transport_category_id = this.selects.transports[
            index
          ].value;
        }
      }
    },
    updateOrder(): void {
      if (this.updatedComponents.indexOf("order") === -1) {
        this.updatedComponents.push("order");
      }
    },
    updateServices(): void {
      if (this.updatedComponents.indexOf("services") === -1) {
        this.updatedComponents.push("services");
      }
    },
    updateScannedDocuments(): void {
      if (this.updatedComponents.indexOf("scanned_documents") === -1) {
        this.updatedComponents.push("scanned_documents");
      }
    },
    openAllocationTab(): void {
      this.selectedTab = 0;
    }
  }
});
